<template>
  <div class="home">
    <div>
      <img src="../../../public/images/xqy/竖版门铃DW4/images/竖版门铃DW4_01.jpg" lazy width="100%" height="100%">
      <img src="../../../public/images/xqy/竖版门铃DW4/images/竖版门铃DW4_02.jpg" lazy width="100%" height="100%">
      <img src="../../../public/images/xqy/竖版门铃DW4/images/竖版门铃DW4_03.jpg" lazy width="100%" height="100%">
      <img src="../../../public/images/xqy/竖版门铃DW4/images/竖版门铃DW4_04.jpg" lazy width="100%" height="100%">
      <img src="../../../public/images/xqy/竖版门铃DW4/images/竖版门铃DW4_05.jpg" lazy width="100%" height="100%">
      <img src="../../../public/images/xqy/竖版门铃DW4/images/竖版门铃DW4_06.jpg" lazy width="100%" height="100%">
      <img src="../../../public/images/xqy/竖版门铃DW4/images/竖版门铃DW4_07.jpg" lazy width="100%" height="100%">
      <img src="../../../public/images/xqy/竖版门铃DW4/images/竖版门铃DW4_08.jpg" lazy width="100%" height="100%">
      <img src="../../../public/images/xqy/竖版门铃DW4/images/竖版门铃DW4_09.jpg" lazy width="100%" height="100%">
      <img src="../../../public/images/xqy/竖版门铃DW4/images/竖版门铃DW4_10.png" lazy width="100%" height="100%">
    </div>
    <foot></foot>
    <navigation></navigation>
  </div>
</template>

<script>
import elMenu from '../../../src/components/elMenu'
import foot from '../../../src/components/foot'
import navigation from '../../../src/components/navigation'
export default {
  name: "竖版门铃-DW4",
  components: {
    elMenu, foot, navigation
  },
}
</script>

<style scoped>

</style>